<template>
  <main>
    <sqr-progress v-show="loading" />
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="isManager">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="company && account && event">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account', params: { cid, aid } }"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{ name: 'events', params: { cid, aid } }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_events') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'event', params: { cid, aid } }"><a><interval-name :interval="event"/></a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 pt-4 pb-32" v-if="event">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-9-tablet is-6-desktop">
            <status-message
              v-if="!statusChanged"
              :record="event"
              @click="statusSet('pending')"
            />

            <div class="field columns" style="margin-bottom: 0">
              <div class="column">
                <div class="field">
                  <sqr-input-date
                    :disabled="disabled"
                    :invalid="$v.event.startDate.$error"
                    :value="event.startDate"
                    @change="fieldSetGen({ field: 'startDate', value: $event })"
                    label="event_start_date"
                  />
                  <div class="help is-danger" v-if="$v.event.startDate.$error">
                    {{ $t('validation_required') }}
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <sqr-input-date
                    :disabled="disabled"
                    :value="event.endDate"
                    @change="fieldSetGen({ field: 'endDate', value: $event })"
                    label="event_end_date"
                  />
                </div>
              </div>
            </div>

            <div class="field">
              <sqr-input-display
                label="event_entry"
                :value="event.entryModelId"
                :disabled="disabled"
                :invalid="$v.event.entryModelId.$error"
                @click="entryModelPick = !disabled"
              >
                <span class="flex items-center" v-if="entry">
                  <div class="w-2 h-6 mr-2" :style="entryStyle"></div>
                  <div class="icon mr-2">
                    <fa :icon="['fal', entry.icon]" />
                  </div>
                  <div>{{ entry | name }}</div>
                </span>
              </sqr-input-display>
              <entry-model-modal
                v-if="entryModelPick"
                label="event_entry_pick"
                @select="
                  fieldSetGen({ field: 'entryModelId', value: $event.id })
                "
                @close="entryModelPick = false"
              />
              <div class="help is-danger" v-if="$v.event.entryModelId.$error">
                {{ $t('validation_required') }}
              </div>
            </div>

            <details class="border p-2 rounded my-4">
              <div class="field">
                <label class="label">{{ $t('event_weekday_exception') }}</label>
                <div class="columns is-gapless is-mobile">
                  <div class="column">
                    <label class="checkbox"
                      ><input
                        :disabled="disabled"
                        :value="1"
                        type="checkbox"
                        v-model="weekDayExceptions"
                      />
                      {{ $t('event_weekday_exception_mon') }}</label
                    >
                  </div>
                  <div class="column">
                    <label class="checkbox"
                      ><input
                        :disabled="disabled"
                        :value="2"
                        type="checkbox"
                        v-model="weekDayExceptions"
                      />
                      {{ $t('event_weekday_exception_tue') }}</label
                    >
                  </div>
                  <div class="column">
                    <label class="checkbox"
                      ><input
                        :disabled="disabled"
                        :value="3"
                        type="checkbox"
                        v-model="weekDayExceptions"
                      />
                      {{ $t('event_weekday_exception_wed') }}</label
                    >
                  </div>
                  <div class="column">
                    <label class="checkbox"
                      ><input
                        :disabled="disabled"
                        :value="4"
                        type="checkbox"
                        v-model="weekDayExceptions"
                      />
                      {{ $t('event_weekday_exception_thu') }}</label
                    >
                  </div>
                  <div class="column">
                    <label class="checkbox"
                      ><input
                        :disabled="disabled"
                        :value="5"
                        type="checkbox"
                        v-model="weekDayExceptions"
                      />
                      {{ $t('event_weekday_exception_fri') }}</label
                    >
                  </div>
                  <div class="column">
                    <label class="checkbox"
                      ><input
                        :disabled="disabled"
                        :value="6"
                        type="checkbox"
                        v-model="weekDayExceptions"
                      />
                      {{ $t('event_weekday_exception_sat') }}</label
                    >
                  </div>
                  <div class="column">
                    <label class="checkbox"
                      ><input
                        :disabled="disabled"
                        :value="7"
                        type="checkbox"
                        v-model="weekDayExceptions"
                      />
                      {{ $t('event_weekday_exception_sun') }}</label
                    >
                  </div>
                </div>
              </div>

              <div class="py-8">
                <div
                  class="field"
                  v-for="entry in event.entries"
                  :key="entry.date"
                >
                  <label class="label">
                    <span class="mr-1">{{ entry.date | weekDayLong }}</span>
                    <span class="has-text-weight-normal"
                      >({{ entry.date | week }})</span
                    >
                  </label>
                  <div class="card" v-if="entry">
                    <week-day-entry
                      :can-remove="false"
                      :entry="entry"
                      @change="entryChange($event)"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </div>
            </details>

            <div class="field">
              <sqr-fire-files
                icon="upload"
                label="event_attachments"
                placeholder="event_attachments_upload"
                :value="event.attachments"
                :prefix="attachmentsPrefix"
                @change="fieldSet({ field: 'attachments', value: $event })"
                color="link"
                :disabled="disabled"
              />
            </div>

            <div class="field">
              <sqr-input-textarea
                :disabled="disabled"
                :value="event.note"
                @change="fieldSet({ field: 'note', value: $event })"
                label="event_note"
              />
            </div>

            <div class="field" v-if="isManagerRW">
              <label class="label">{{ $t('event_status') }}</label>
              <status-buttons
                :status="event.status"
                :disabled="disabled"
                @change="statusSet($event)"
              />
            </div>

            <div class="field pt-8">
              <div class="buttons is-right">
                <div class="control" v-if="event && eid" style="flex-grow: 1">
                  <sqr-button
                    :disabled="disabled"
                    :is-loading="removing"
                    @click="removeAndGo()"
                    color="danger"
                    label="event_remove"
                    is-outlined
                  />
                </div>
                <div class="control">
                  <sqr-button
                    @click="$router.back()"
                    color="light"
                    label="buttons_cancel"
                  />
                </div>
                <div class="control" v-if="!allAccounts">
                  <sqr-button
                    :disabled="disabled"
                    :is-loading="saving"
                    @click="validateAndSave(true)"
                    color="primary"
                    label="event_save"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.buttons .control {
  @apply ml-2;
}
.buttons .control:first-child {
  @apply ml-0;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';

import account from './account';
import event from './event';

import SqrProgress from '@/sqrd/SqrProgress';
import IntervalName from '@/components/IntervalName';
import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';

export default {
  name: 'Event',
  mixins: [account, event],
  components: { SqrProgress, IntervalName, CompanyName, AccountName },
  props: {
    eid: String,
  },
  computed: {
    ...mapState('event', { event: 'record' }),
    ...mapState('event', ['loading', 'loadError']),
  },
  mounted() {
    if (!this.eid) {
      this.init({
        record: {
          status: 'pending',
          companyId: this.cid,
          accountId: this.aid,
          givenName: this.account?.givenName ?? '',
          familyName: this.account?.familyName ?? '',
          weekDayExceptions: [6, 7],
        },
      });
      this.statusChanged = true;
    } else {
      this.sub({
        path: ['companies', this.cid, 'accounts', this.aid, 'events'],
        id: this.eid,
      });
    }
  },
  beforeRouteLeave(from, to, next) {
    this.unsub({ reset: true });
    next();
  },
  methods: {
    ...mapActions('event', ['sub', 'init']),
  },
};
</script>
