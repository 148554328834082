<template>
  <span>
    <span>{{ interval.startDate | dateShort }}</span>
    <span v-if="span"> - </span>
    <span v-if="span">{{ interval.endDate | dateShort }}</span>
  </span>
</template>

<script>
import dateShort from '@/filters/dateShort';

export default {
  name: 'IntervalName',
  props: {
    interval: Object,
  },
  filters: {
    dateShort,
  },
  computed: {
    span() {
      return this.interval?.startDate !== this.interval?.endDate;
    },
  },
};
</script>

<style scoped>
.text-icon {
  width: 1rem;
  display: inline-block;
  text-align: center;
}
</style>
